import { DetalleConteo } from "./detalle-conteo";
import { Documento } from "./documento";

export class ConteoSemanal extends Documento {
    detalles: DetalleConteo[] = [];
    fechaInicio: string | Date;
    fechaFinal: string | Date;
    cantidadDiferencias: number;
    enviadoPorCorreo: boolean;
    fechaInicioConteo: string | Date;
    fechaFinalConteo: string | Date;
    tipoConteoId: number;
  }
  